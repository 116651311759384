import * as React from 'react';
import { useState, useEffect } from 'react';
import { Router, useParams } from "@reach/router"
import CmsPageContent from '../components/cms/CmsPageContent';
import Seo from '../components/layout/Seo';
import Layout from '../components/Layout';

const Details = () => {
    const splat = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const _page = {
        meta_title: 'Preview',
        slug: splat.slug || 'preview',
        meta_description: 'Preview',
        title: 'Preview',
        id: splat.id || 0
    };
    const [page, setPage] = useState(_page);

    useEffect(() => {
        setIsLoading(true);
        console.log('useEffect', splat.slug, splat.id);
        fetch(`/api/get-page?slug=${encodeURIComponent(splat.slug)}&id=${encodeURIComponent(splat.id)}`).
            then(res => res.json()).
            then(data => {
                console.log('data', data);
                setPage(data);
                setIsError(false);
                setIsLoading(false);
            }, err => {
                console.log('err', err);
                setIsError(true);
                setIsLoading(false);
            })
    }, [splat.slug, splat.id]);

    return (
        <>
            <Seo
                title={page.meta_title}
                canonical={'/' + page.slug + '/'}
                description={page.meta_description}
                article={page}
            />
            {isError && <div className="container mx-auto">Error - Can't load page!!!</div>}
            {
                isLoading ?
                    <div className={"container mx-auto px-4"}>Loading {page.slug}...</div>
                    :
                    <CmsPageContent page={page} />
            }
        </>
    );
}

const PreviewPage = () => {
    const page = { slug: 'preview', title: 'Preview' };
    return (
        <Layout
            breadcrumbs={true}
            canonical={'/' + page.slug + '/'}
            title={page.title}
            className="container mx-auto flex">
            <Router basepath="/preview" >
                <Details path="/:id/:slug" />
                <Details path="/:slug" />
                <Details path="/" />
            </Router>
        </Layout>
    );
};

export default PreviewPage;
